import { INDIVIDUAL_FORM, INDIVIDUAL_FORM_EDITABLE, INDIVIDUAL_FORM_FORMATTED, INDIVIDUAL_FORM_HISTORY, LANDING_FORMS, SUBMIT_TEMPLATE_FORM, FORM_ACTION, TASK_ACTION } from "../actions-index";


export function form(state = {}, action = {}) {
  const { type, payload = {} } = action;
  
  const id = payload?.id
  const constructFieldStructure = (field = "") => {
    if(!id) {
      return state
    }
    let obj = {
      ...state,
      [id]: {
        ...state?.[field]?.[id] ?? {},
        ...payload
      }
    }

    if(Object.keys(payload?.[field] ?? {})) {
      obj[id].status = payload?.status ?? 'success'
    }
    return {
      ...state,
      [field]: obj
    }
  }

  switch (type) {
    case INDIVIDUAL_FORM:
      return id ? {
        ...state,
          [id]: {
            ...payload
          }
        } : state
    case INDIVIDUAL_FORM_FORMATTED: {
      return constructFieldStructure('formatted')
    }
    case INDIVIDUAL_FORM_EDITABLE: {
      return constructFieldStructure('edit')
    }
    case INDIVIDUAL_FORM_HISTORY: {
      return constructFieldStructure('history')
    }
    case SUBMIT_TEMPLATE_FORM:
      return {
        submission: payload
      }
    default:
      return state;
  }
}

export function landingForms(state = {}, action = {}) {
  return (action.type === LANDING_FORMS ? action.payload : state);
}

export function formAction(state = {}, action = {}) {
  return (action.type === FORM_ACTION ? action.payload : state);
}

export function taskAction(state = {}, action = {}) {
  return (action.type === TASK_ACTION ? action.payload : state);
}