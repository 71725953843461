import { isValid } from "date-fns";
import { inputMappings } from "../../../../util/enums";

export const createDateTimeQuestions = (dateId, startId, endId) => {
  let questions = [
    {
      id: dateId,
      type: inputMappings.date,
      title: "Date",
    },
  ];
  if (!startId && !endId) {
    questions.push({
      id: `${dateId}-time`,
      type: inputMappings.time,
      title: "Time",
    });
  }
  if (startId) {
    questions.push({
      id: startId,
      type: inputMappings.time,
      title: "Start Time",
    });
  }
  if (endId) {
    questions.push({
      id: endId,
      type: inputMappings.time,
      title: "End Time",
    });
  }
  return questions;
};

export const formatDate = (currentValue) => {
  let value = null;
  if (isValid(currentValue)) {
    value = currentValue;
  } else if (currentValue) {
    const parsedDate = new Date(currentValue);
    if (isValid(parsedDate)) {
      value = parsedDate;
    }
  }
  return value
}

export const alterTimeToMatchDate = (existingDate, newValue) => {
  let value = newValue;
  let month, day, year;
  if (isValid(existingDate)) {
    day = existingDate.getDate();
    month = existingDate.getMonth();
    year = existingDate.getFullYear();
    if (isValid(value)) {
      value.setDate(day);
      value.setMonth(month);
      value.setFullYear(year);
    }
  }
  return value
};