import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { invert } from "lodash";
import { checkPendingForm } from "../actions";
import { FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

export default function GraduateFacultyNomination(props = {}) {
  console.log("function GraduateFacultyNomination props has ", props);
  const { Component, match = {} } = props;
  const { params = {}, pathname = "" } = match;
  const { formType: formTypeFriendly } = params;
  const formType = invert(FORM_TYPE_HTML_FRIENDLY)[formTypeFriendly];
  const isCreate = pathname.includes("/create");
  const isEdit = pathname.includes("/edit");
  
  const navigate = useNavigate();
  const data = useSelector((state) => (state?.person?.data ?? {}));
  // const { academicPlan = null, hasDoctoralAcademicPlan = false, osuid } = user;
  const { emplid } = data;
  const isValidating = useRef(null);
  const validateSubmit = useCallback(async () => {  
      const hasPendingForm = await checkPendingForm({ formType, studentid: emplid }, false);
      if(hasPendingForm) return "Unable to submit Grad Faculty Nomination. There is an existing Pending or Approved Form with the Graduate School."
      return null;
  }, [formType, emplid]);

  const navigateToLandingPage = useCallback((errorMessage) => {
    navigate(`/form/${formTypeFriendly}`, { state: { errorMessage }});
  }, [formTypeFriendly, navigate]);

  const validate = useCallback(async () => {
    isValidating.current = true;
    if(isCreate) {
      console.log(`GraduateFacultyNomination.js isCreate so do what? ${isCreate} formType ${formType} emplid ${emplid}`);
      return null;
      // if(!academicPlan) return navigateToLandingPage("Unable to find a valid academic plan.  If you are a current student, please contact your graduate office.");
      // if(!hasDoctoralAcademicPlan) return navigateToLandingPage("We were unable to find an enrollment for a doctoral academic plan.  Please contact your graduate program office.");
      // const hasPendingForm = await checkPendingForm({ formType, studentid: emplid }, true);
      // console.log(`GraduateFacultyNomination.js hasPendingForm is ${hasPendingForm}`);
      // if(hasPendingForm) return navigateToLandingPage(`There is an existing New, Pending or Approved Form with the Graduate School for ${emplid}.`);
    }
  // }, [formType, navigateToLandingPage, isCreate, emplid]);
  }, [formType, isCreate, emplid]);

  // on mount, perform validation - use ref to prevent duplicate mounts in development with StrictMode
  useEffect(() => {
    if(isValidating.current === null) validate();
    return () => {
      isValidating.current = false;
    };
  }, [isValidating, validate]);

  return (<Component {...props} isValidating={isValidating?.current} validateSubmit={validateSubmit} />);
}