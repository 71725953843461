import {
  ERROR_IN_FORM,
  EXISTING_FORMS,
  SUBMIT_TEMPLATE_FORM,
} from "../actions-index";
import { generateAction } from "../redux-helpers";
import * as Queries from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { getFormByType } from "./paths";
import { transformFormDataToPayload } from "./transform";
import { createFormTypeBasedFormRequest } from "../graphql/custom";
import { APPROVAL_STATUS, FORM_TYPE_HTML_FRIENDLY } from "../util/constants";
import { forOwn } from "lodash";

const API = generateClient();

export function submitTemplateForm() {
  return (dispatch) => {
    dispatch(
      generateAction(SUBMIT_TEMPLATE_FORM, {
        completed: true,
      })
    );
  };
}

function buildFormContext(formType, formData, user) {
  const formDefinition = (getFormByType(formType) ?? {});
  const { isStudentForm = false, contextFields = {} } = formDefinition;
  if(isStudentForm === true) {
    return { studentid: user.osuid, academicPlan: user?.academicPlan, careerNumber: user?.careerNumber, pcdIndicator: user?.pcdIndicator };
  } else {
    const getContextFieldValue = (contextField) => {
      let contextFieldValue = null;
      const contextFieldTokens = contextField.split(".");
      if(contextFieldTokens.length > 1) {
          let value = formData[contextFieldTokens[0]];
          if(value) {
            for(let i = 1; i < contextFieldTokens.length; i++) {
              value = value[contextFieldTokens[i]];
              if(!value) break;
            }
          }
          if(value) contextFieldValue = value;
      } else {
        contextFieldValue = formData[contextFieldTokens[0]];
      }
      return contextFieldValue;
    };
    const formContext = { requesterId: user.osuid };
    forOwn(contextFields, (value, key) => {
      formContext[key] = getContextFieldValue(value);
    });
    return formContext;
  }
}

export function onFormSave(values) {
  const {  formType, formData, user } = values
  const { body } = transformFormDataToPayload(formData, formType)
  const formRequest = createFormTypeBasedFormRequest(formType, false)
  const formContext = buildFormContext(formType, body, user);
  return async (dispatch) => {
    dispatch(
      generateAction(SUBMIT_TEMPLATE_FORM, {
        status: "loading"
      })
    );
    try {
      let input =  {
        action: 'SAVE',
        formType,
        ...body,
        ...formContext
      }
      if(values.id) {
        input.id = values.id
      }

      const response = await API.graphql({ query: formRequest, variables: {
        input
      } });
      if(response?.data?.updateForm?.form?.id) {
        let success = {
          status: response?.data?.updateForm?.status,
          id: response?.data?.updateForm?.form?.id,
          osuid: (response?.data?.updateForm?.form?.requesterId ? response?.data?.updateForm?.form?.studentid : null) // when the form has a requester, set the osuid to the student so that it's included in the form route
        }
        dispatch(
          generateAction(SUBMIT_TEMPLATE_FORM, success)
        );
      } else {
        throw new Error("Unexpected response from update form")
      }
    } catch (error) {
      console.error(error)
      dispatch(
        generateAction(SUBMIT_TEMPLATE_FORM, {
          status: "error"
        })
      );
      
    }
  };
}

export function onFormSubmit(values, validate) {
  const { id, formType, formData, user } = values
  const { errors, componentState, body } = transformFormDataToPayload(formData, formType)
  const formRequest = createFormTypeBasedFormRequest(formType, false)
  const formContext = buildFormContext(formType, body, user);
  if(errors?.length) {
    return (dispatch) => {
      dispatch(
        generateAction(ERROR_IN_FORM, {
          [id ?? FORM_TYPE_HTML_FRIENDLY[formType]]: {
            state: componentState,
            errors
          }
        })
      );
    };
  }
  return async (dispatch) => {
    const errorMessage = (validate ? (await validate()) : null); // form-specific validation
    if(errorMessage) {
      dispatch(
        generateAction(SUBMIT_TEMPLATE_FORM, {
          message: errorMessage,
          status: "error"
        })
      );
    } else {
      dispatch(
        generateAction(SUBMIT_TEMPLATE_FORM, {
          status: "loading"
        })
      );
      try {
  
        const response = await API.graphql({ query: formRequest, variables: {
          input: {
            action: 'SUBMIT',
            formType,
            ...body,
            ...formContext
          }
        } });
        
        if(response?.data?.updateForm?.form?.id) {
          dispatch(
            generateAction(SUBMIT_TEMPLATE_FORM, {
              status: response?.data?.updateForm?.status,
              id: response?.data?.updateForm?.form?.id,
              completed: true,
              osuid: (response?.data?.updateForm?.form?.requesterId ? response?.data?.updateForm?.form?.studentid : null) // when the form has a requester, set the osuid to the student so that it's included in the form route
            })
          );
        } else {
          throw new Error("Unexpected response from update form")
        }
      } catch (error) {
        console.error(error)
        dispatch(
          generateAction(SUBMIT_TEMPLATE_FORM, {
            status: "error"
          })
        );
        
      }
    }
  };
}

export function checkExistingForm(filter = {}) {
  const { formType } = filter
  return async (dispatch) => {
    const response = await API.graphql({ query: Queries.getForms, variables: { filter: {
      formType
    } } });
    if(formType) {
      const matchingForms = (response?.data?.getForms?.forms ?? []).filter(form => form.formType === formType)
      if (matchingForms?.length) {
        dispatch(generateAction(EXISTING_FORMS, {
          formType,
          forms: matchingForms
        }))
      }
    }
  }
}

export async function checkPendingForm(filter = {}, includeNew = false) {
  const response = await API.graphql({ query: Queries.getForms, variables: { filter } });
  
  const errors = (response?.errors ?? []);
  if(errors.length > 0) throw new Error(errors[0].message);
  
  let forms = (response?.data?.getForms?.forms ?? []);
  const filterStatuses = [APPROVAL_STATUS.APPROVED, APPROVAL_STATUS.PENDING];
  if(includeNew === true) filterStatuses.push(APPROVAL_STATUS.NEW);
  forms = forms.filter(form => (filterStatuses.includes(form.status)));
  return forms.length > 0;
}