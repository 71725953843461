import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

const data = [
  {
    id: "app-candidacy-info",
    title: "Candidacy Information",
    questions: [
      {
        id: "term",
        title: "Term",
        required: true,
        dataField: "termCode",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        filter: {
          showFromPresent: 2
        }
      },
      {
        title: "Candidacy Type",
        id: "candidacy-type",  //Updatte checkbox below if this changes
        required: true,
        dataField: "candidacyType",
        type: inputMappings.dropDown,
        choices: [
          {
            id: "first-candidacy",
            title: "First Candidacy",
            value: "first-candidacy",
            alter: {
              sectionId: "app-candidacy-info",
              questionId: 'request-rep',
              dataField: "requestGradFacultyRep",
              value: 'N'
            }
          },
          {
            id: "second-candidacy",
            title: "Second Candidacy",
            value: "second-candidacy",  //Updatte checkbox below if this changes
            alter: {
              sectionId: "app-candidacy-info",
              questionId: 'request-rep',
              dataField: "requestGradFacultyRep",
              value: 'Y',
              disabled: true
            }
          },
          {
            id: "supplemental-candidacy",
            title: "Supplemental Candidacy",
            value: "supplemental-candidacy",
            alter: {
              sectionId: "app-candidacy-info",
              questionId: 'request-rep',
              dataField: "requestGradFacultyRep",
              value: 'N'
            }
          },
        ],
      },
      {
        id: "request-rep",
        type: inputMappings.checkbox,
        dataField: "requestGradFacultyRep",
        defaultValue: 'N',
        choices: [
          {
            id: "grad-faculty-rep",
            value: "Y",
            title:
              "I would like to request a Graduate Faculty representative (Required for Second Candidacy).",
          },
        ],
      }
    ]
  },
  {
    id: "written-portion",
    title: "Written portion of candidacy examination",
    questions: [
      {
        id: "written-date-begin-date",
        dataField: "beginDate",
        type: inputMappings.date,
        title: 'Begin Date',
        required: true,
      },
      {
        id: "written-date-end-date",
        dataField: "endDate",
        type: inputMappings.date,
        title: 'End Date',
        required: true,
      },
    ],
  },
  {
    id: "oral-portion",
    title: "Oral portion of candidacy examination",
    questions: [
      {
        id: "oral-date-input-group",
        title: "Date of Oral Examination",
        type: inputMappings.datetime,
        dataField: "oralDate",
        startTime: true,
        endTime: true,
        required: true,
        summary:
          "The oral examination must be on a University business day and must begin no earlier than 7:30 AM, but end no later than 5:30 PM, and must be at least two hours in length.",
      },
      {
        id: "video-conf-component",
        type: inputMappings.checkbox,
        defaultValue: 'N',
        dataField: "videoConference",
        choices: [
          {
            id: "yes-video-conf-component",
            value: "Y",
            title:
              "There will be a video conference component to this examination.",
            branch: "video-conf-platform",
          },
        ],
      },
      {
        id: "video-conf-platform",
        title:
          "Video Conference Platform (example: Zoom meeting, Microsoft Teams, Skype, etc.)",
        dependencies: ["video-conf-component"],
        dataField: "videoConferencePlatform",
        type: inputMappings.textInput,
        summary: (
          <Fragment>
            <Mui.Typography variant="body2" className="margin-bottom-2">
              For examinations that are entirely virtual you do not need to
              include room and building.
            </Mui.Typography>
            <Mui.Typography variant="body2">
              If your examination is both virtual and in person please include
              room and building.
            </Mui.Typography>
          </Fragment>
        ),
      },
      {
        id: "room-number-building",
        type: inputMappings.inputGroup,
        numberOfResponsesMaximum: 1,
        questions: [
          {
            id: "room-num",
            title: "Room Number",
            dataField: "roomNumber",
            type: inputMappings.textInput,
          },
          {
            id: "building",
            title: "Building",
            dataField: "building",
            type: inputMappings.textInput,
          },
        ],
      },
    ],
  },
  {
    title: "Candidacy Committee",
    id: "candidacy-committee",
    questions: [
      {
        id: "advisor-subheading",
        type: "subheading",
        title: "Advisor Information",
      },
      {
        id: "committee-advisor",
        type: "dropDown",
        dataField: "committee.advisor",
        title: "Advisor",
        summary: null,
        required: true,
        subtype: dropdownSubtypes.advisor,
      },
      {
        id: "co-advisor",
        type: "dropDown",
        title: "Co-Advisor (if applicable)",
        dataField: "committee.coAdvisor",
        summary: null,
        required: false,
        subtype: dropdownSubtypes.coAdvisor,
      },
      {
        id: "grad-faculty-rep",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.gradFacultyRepresentative,
        dataField: "committee.gradFacultyRepresentative",
        title: "Graduate Faculty Representative",
        required: false,
      },
      {
        id: "committee-members",
        type: "inputGroup",
        title: "Committee Members",
        subtitle: "Graduate Faculty committee members",
        summary:
          "(M)aster’s level faculty selection will require approval of the Graduate Studies Committee Chair.",
        required: null,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 4,
        dependencies: [],
        choices: [],
        dataField: "committee.members",
        questions: [
          {
            id: "committee-member-info",
            type: "dropDown",
            title: "Faculty Member",
            subtype: dropdownSubtypes.facultyMember,
            summary: null,
            required: false,
          },
        ],
      },
      {
        id: "external-members",
        type: "inputGroup",
        title: "External Members",
        subtitle: "Non-Graduate Faculty committee members",
        summary:
          "For each external committee member, completion & submission of Committee and Examination Petition is required.",
        required: null,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 4,
        dependencies: [],
        choices: [],
        dataField: "committee.externalMembers",
        questions: [
          {
            id: "first-name",
            type: "textInput",
            title: "First Name",
            dataField: "firstName",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: [],
          },
          {
            id: "last-name",
            type: "textInput",
            title: "Last Name",
            dataField: "lastName",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: [],
          },

          {
            id: "email",
            type: "textInput",
            title: "Email Address",
            dataField: "email",
            summary: null,
            required: true,
            numberOfResponsesMinimum: 1,
            numberOfResponsesMaximum: 1,
            dependencies: [],
            choices: [],
          },
        ],
      },
    ]
  },
];


export const additionalFilters = [ {
  id: "oral-date-after",
  title: "Oral Date After",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "oral-date-before",
  title: "Oral Date Before",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "candidacy-type",
  title: "Candidacy Type",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.candidacyType
},
  {
    id: "category-m",
    title: "Category M",
    required: false,
    type: inputMappings.dropDown,
    choices: [{
      id: "all-category-m",
      title: "All",
      value: "All"
    },
    {
      id: "yes-category-m",
      title: "Yes",
      value: "Yes"
    },
    {
      id: "no-category-m",
      title: "No",
      value: "No"
    }
    ]
  }];

export default data;
