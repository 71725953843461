export const ACCESSIBLE_REFLOW_HEIGHT = `399px`;
export const EXTERNAL_ROLE = "EXTERNAL_COMMITTTEE";
export const AWS_LAMBDA = "AWS_LAMBDA";
export const ACTION_STATUS = {
    ERROR: "error",
    FORBIDDEN: "forbidden",
    LOADING: "loading",
    NOT_FOUND: "notfound",
    SUCCESS: "success",
    UNAUTHORIZED: "unauthorized"
};
export const APPOINTMENT_STATUS = {
    Inactive: "N",
    Active: "Y"
}
export const ACTIVE = {
    NO: "N",
    YES: "Y"
};
export const AFFILIATION_TYPE = {
    DEPARTMENT_CHAIR: "DChair",
    GRADUATE_CHAIR: "GChair",
    GRADUATE_CONTACT: "GContact",
    GRADUATE_PROGRAM_INQUIRER: "GInquirer"
};
export const AFFILIATION = {
    [AFFILIATION_TYPE.DEPARTMENT_CHAIR]: "Department Chair",
    [AFFILIATION_TYPE.GRADUATE_CHAIR]: "Graduate Chair",
    [AFFILIATION_TYPE.GRADUATE_CONTACT]: "Graduate Contact",
    [AFFILIATION_TYPE.GRADUATE_PROGRAM_INQUIRER]: "Graduate Program Inquirer"
};
export const APPOINTMENT = {
    CATEGORY: {
        CP: "P",
        CM: "M",
    },
    FACULTY_TYPE: {
        CURR: "Current",
        CONT: "Continuous",
        AUTH: "Authorized",
        EMER: "Emeritus"
    }
};
export const APPLICATION_ROLE = {
    CONTACT_APPROVER: "GRAD-SCHOOL-CONTACT-APPROVER",
    ENROLLMENT_ADMIN: "GRAD-SCHOOL-ENROLLMENT-ADMIN",
    FACULTY_APPROVER: "GRAD-SCHOOL-FACULTY-APPROVER",
    GRADUATION_ADMIN: "GRAD-SCHOOL-GRADUATION-ADMIN",
    INQUIRER: "GRAD-SCHOOL-INQUIRER"
};
export const APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX = "GP#";
export const APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX = "GPADMIN#";
export const APPLICATION_ROLE_GRAD_FACULTY_PREFIX = "GF#";
export const CAREER = {
    GRAD: "GRAD",
    PROFESSIONAL: {
        DENT: "DENT",
        LAW: "LAW",
        MED: "MED",
        OPT: "OPT",
        PHP: "PHP",
        VMED: "VMED"
    }
};
export const DEGREE = {
    AUD: "AUD",
    CERTIFICATE: "CRT",
    DMA: "DMA",
    DNP: "DNP",
    DPT: "DPT",
    EDD: "EDD",
    INTERDISCIPLINARY_SPECIALIZATION: "ISP",
    MINOR: "MIN",
    OTD: "OTD",
    PHD: "PHD",
    SPECIALIZATION: "SPC"
};
export const DELIMITER = {
    ACADEMIC_PLAN: "-"
};
export const EXTERNAL_LINK_DESCRIPTOR = "external-link-helper";
export const FORCED_AUTHENTICATION = process.env.REACT_APP_FORCED_AUTHENTICATION === "true";
export const GRAD_LEVEL = {
    DMA: "DMA",
    DOCTORAL: "Doctoral",
    EDUCATIONAL_SPECIALIST: "Educational Specialist",
    MASTER: "Master",
    MASTERCB: "MasterCB",
    PROFESSIONAL: "Professional"
};
export const OSU_NAV_ID = 'osu-navbar';
export const NAVBAR_WRAPPER_ID = "app-header";
export const VIEW_ONLY = {
    NO: "N",
    YES: "Y"
};
export const MAIN_ID = 'page-content';
export const SORT_DIRECTION = {
    ASCENDING: "asc",
    DESCENDING: "desc"
};
export const STATUS = {
    ACTIVE: "Active",
    INACTIVE: "Inactive"
};
export const TOC_WIDTH = `15rem`;
export const MAX_LENGTH_EMPLID = '9'

export const formCategoryIdentifiers = {
    FORM_CATEGORY: "FORM_CATEGORY",
    GRADUATION_AND_EXAMINATION: "GRADUATION_AND_EXAMINATION",
    ENROLLMENT: "ENROLLMENT",
    REPORTS: "REPORTS"
}

export const FORM_CATEGORY = {
    [formCategoryIdentifiers.GRADUATION_AND_EXAMINATION]: "Graduation and Examination",
    [formCategoryIdentifiers.ENROLLMENT]: "Enrollment",
    [formCategoryIdentifiers.REPORTS]: "Reports",
}

export const formTypeIdentifiers = {
    FORM_TYPE: "FORM_TYPE",
    MINORS_AND_SPECIALIZATIONS: "MINORS_AND_SPECIALIZATIONS",
    REPORT_ON_FINAL_DOCUMENT: "REPORT_ON_FINAL_DOCUMENT",
    APPLICATION_FOR_CANDIDACY_EXAM: "APPLICATION_FOR_CANDIDACY_EXAM",
    APPLICATION_TO_GRADUATE: "APPLICATION_TO_GRADUATE",
    COMBINED_DEGREE_PROGRAM: "COMBINED_DEGREE_PROGRAM",
    GRAD_FACULTY_NOMINATION: "GRAD_FACULTY_NOMINATION",
    LATE_COURSE_PETITION: "LATE_COURSE_PETITION",
    APPLICATION_FOR_CERTIFICATE_COMPLETION: "APPLICATION_FOR_CERTIFICATE_COMPLETION",
    APPLICATION_FOR_FINAL_EXAM: "APPLICATION_FOR_FINAL_EXAM",
    LEAVE_OF_ABSENCE_FROM_PROGRAM: "LEAVE_OF_ABSENCE_FROM_PROGRAM",
    DUAL_DEGREE_PROGRAM: "DUAL_DEGREE_PROGRAM",
    SPECIALIZATION: "SPECIALIZATION",
    TRANSFER_GRAD_CREDIT: "TRANSFER_GRAD_CREDIT",
    TRANSCRIPT_REQUEST: "TRANSCRIPT_REQUEST",
    WITHDRAWAL_FROM_PROGRAM: "WITHDRAWAL_FROM_PROGRAM",
    APPLICATION_FOR_PROF_EXAM: "APPLICATION_FOR_PROF_EXAM",
    DELAY_OF_FINAL_DOCUMENT: "DELAY_OF_FINAL_DOCUMENT",
    REPORT_ON_CANDIDACY: "REPORT_ON_CANDIDACY",
    REPORT_ON_FINAL_EXAM: "REPORT_ON_FINAL_EXAM",
    COMMITTEE_AND_EXAM_PETITION: "COMMITTEE_AND_EXAM_PETITION",
    REPORT_ON_PROF_EXAM: "REPORT_ON_PROF_EXAM",
    TRANSFER_GRAD_PROGRAM: "TRANSFER_GRAD_PROGRAM"
} 
export const FORM_TYPE = {
    [formTypeIdentifiers.APPLICATION_TO_GRADUATE]: "Application To Graduate",
    [formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]: "Application For Candidacy Exam",
    [formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION]: "Application For Certificate Completion",
    [formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]: "Application For Final Examination",
    [formTypeIdentifiers.APPLICATION_FOR_PROF_EXAM]: "Application For Professional Examination",
    [formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS]: "Minors & Interdisciplinary Specializations",
    [formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT]: "Report On Final Document",
    [formTypeIdentifiers.COMBINED_DEGREE_PROGRAM]: "Combined Degree Program Plan",
    [formTypeIdentifiers.GRAD_FACULTY_NOMINATION]: "Grad Faculty Nomination",
    [formTypeIdentifiers.LATE_COURSE_PETITION]: "Late Course Petition",
    [formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM]: "Leave Of Absence From Program",
    [formTypeIdentifiers.DUAL_DEGREE_PROGRAM]: "Dual Degree Program",
    [formTypeIdentifiers.SPECIALIZATION]: "Specialization",
    [formTypeIdentifiers.TRANSFER_GRAD_CREDIT]: "Transfer of Graduate Credit",
    [formTypeIdentifiers.TRANSCRIPT_REQUEST]: "Transcript Designation Request",
    [formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM]: "Withdraw From Program",
    [formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT]: "Delay Of Final Document",
    [formTypeIdentifiers.REPORT_ON_CANDIDACY]: "Report On Candidacy",
    [formTypeIdentifiers.REPORT_ON_FINAL_EXAM]: "Report On Final Examination",
    [formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION]: "Committee And Examination Petition",
    [formTypeIdentifiers.REPORT_ON_PROF_EXAM]: "Report on Professional Examination",
    [formTypeIdentifiers.TRANSFER_GRAD_PROGRAM]: "Transfer Graduate Program"
};

export const FORM_TYPE_HTML_FRIENDLY = {
    [formTypeIdentifiers.APPLICATION_TO_GRADUATE]: "application-to-graduate",
    [formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM]: "application-for-candidacy-exam",
    [formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION]: "application-for-certificate-completion",
    [formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM]: "application-for-final-examination",
    [formTypeIdentifiers.APPLICATION_FOR_PROF_EXAM]: "application-for-professional-examination",
    [formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS]: "minors-and-interdisciplinary-specializations",
    [formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT]: "report-on-final-document",
    [formTypeIdentifiers.COMBINED_DEGREE_PROGRAM]: "combined-degree-program",
    [formTypeIdentifiers.GRAD_FACULTY_NOMINATION]: "graduate-faculty-nomination",
    [formTypeIdentifiers.LATE_COURSE_PETITION]: "late-course-petition",
    [formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM]: "leave-of-absence-from-program",
    [formTypeIdentifiers.DUAL_DEGREE_PROGRAM]: "dual-degree-program-plan",
    [formTypeIdentifiers.SPECIALIZATION]: "specialization",
    [formTypeIdentifiers.TRANSFER_GRAD_CREDIT]: "transfer-of-graduate-credit",
    [formTypeIdentifiers.TRANSCRIPT_REQUEST]: "transcript-designation-request",
    [formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM]: "withdrawal-from-program",
    [formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT]: "delay-of-final-document",
    [formTypeIdentifiers.REPORT_ON_CANDIDACY]: "report-on-candidacy",
    [formTypeIdentifiers.REPORT_ON_FINAL_EXAM]: "report-on-final-examination",
    [formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION]: "committee-and-examination-petition",
    [formTypeIdentifiers.REPORT_ON_PROF_EXAM]: "report-on-professional-examination",
    [formTypeIdentifiers.TRANSFER_GRAD_PROGRAM]: "transfer-graduate-program"
};

export const APPROVAL_STATUS = {
    APPROVED: "APPROVED",
    CANCELLED: "CANCELLED",
    COMPLETED: "COMPLETED",
    DENIED: "DENIED",
    NEW: "NEW",
    PENDING: "PENDING",
    SATISFACTORY: "SATISFACTORY",
    UNSATISFACTORY: "UNSATISFACTORY"
};

export const APPROVAL_SUBSTATUS = {
    GS_PENDING: "GS_PENDING",
    DEPT_PENDING: "DEPT_PENDING"
};

export const DAYS_OVERDUE = 5
export const SUPPORT_EMAIL = 'gradforms@osu.edu'

export const personInfo = {
    read:  {
        emplid: "OSUID",
        nameN: "Name .#",
        firstName: "First Name",
        lastName: "Last Name",
        middleName: "Middle Name",
        facultyEmail: "Faculty Email",
        facultyNomination: "Faculty Nomination",
        phone: "Phone",
        address1: "Address",
        program: "Program",
        category: "Category"
    },
    write:  {
        emplid: "OSUID",
        firstName: "First Name",
        lastName: "Last Name",
        middleName: "Middle Name",
        facultyEmail: "Faculty Email",
        facultyNomination: "Faculty Nomination",
        phone: "Phone",
        program: "Program",
        category: "Category"
    },
}

export const WorkflowApprovalTask = {
    ADVISOR_APPROVAL: 'ADVISOR_APPROVAL',
    GRAD_CHAIR_COORDINATOR_APPROVAL: 'GRAD_CHAIR_COORDINATOR_APPROVAL',
    GRADSCHOOL_APPROVAL: 'GRADSCHOOL_APPROVAL',
    FINAL_APPROVAL: 'FINAL_APPROVAL'
}

export const TIME_FORMAT = 'hh:mm aa'
export const DATE_FORMAT = "MM/dd/yyyy"

export const TASK_ACTION = {
    COMPLETE: "Complete"
}