import { uniq, values } from "lodash";
import { APPLICATION_ROLE, APPLICATION_ROLE_GRAD_FACULTY_PREFIX, APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX, APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX,
    CAREER, DEGREE, DELIMITER, EXTERNAL_ROLE } from "../util/constants";

function transformExternalUser(user = {}){
    const { externalUserId, emailAddr, firstName, lastName, roleName, externalKeys } = user;
    return {
        externalUserId,
        email: emailAddr,
        fullName: `${firstName} ${lastName}`,
        name: `${firstName} ${lastName}`,
        initials: `${firstName && firstName.charAt(0)}${lastName && lastName.charAt(0)}`.toUpperCase(),
        applicationRoles: [roleName],
        hasAuthorizedRole: roleName === EXTERNAL_ROLE,
        isExternal: roleName === EXTERNAL_ROLE,
        externalKeys
    }
}

function transformUser(user = {}, studentAcademicPlan = null) {
    const { "cognito:groups": applicationRoles = [], email, displayName, name, osuid, given_name = '', family_name = '' } = user;

    const isContactApprover = applicationRoles.includes(APPLICATION_ROLE.CONTACT_APPROVER);
    const isEnrollmentAdmin = applicationRoles.includes(APPLICATION_ROLE.ENROLLMENT_ADMIN);
    const isFacultyApprover = applicationRoles.includes(APPLICATION_ROLE.FACULTY_APPROVER);
    const isGraduationAdmin = applicationRoles.includes(APPLICATION_ROLE.GRADUATION_ADMIN);
    const isInquirer = applicationRoles.includes(APPLICATION_ROLE.INQUIRER);
    
    const isGradStudent = (studentAcademicPlan ? studentAcademicPlan?.career === CAREER.GRAD : false);
    const isProfessionalStudent = (studentAcademicPlan ? values(CAREER.PROFESSIONAL).includes(studentAcademicPlan?.career) : false);
    const isStudent = (isGradStudent || isProfessionalStudent);
    const hasDoctoralAcademicPlan = (studentAcademicPlan ? [DEGREE.DMA, DEGREE.PHD].includes(studentAcademicPlan.degree) : false);
    const hasProfessionalAcademicPlan = (studentAcademicPlan ? [DEGREE.AUD, DEGREE.DNP, DEGREE.DPT, DEGREE.EDD, DEGREE.OTD].includes(studentAcademicPlan.degree) : false);
    const academicPlan = ((studentAcademicPlan && studentAcademicPlan?.academicPlanCode) ? studentAcademicPlan.academicPlanCode : null);
    const careerNumber = ((studentAcademicPlan && studentAcademicPlan?.careerNumber) ? studentAcademicPlan.careerNumber : null); 
    const pcdIndicator = ((studentAcademicPlan && studentAcademicPlan?.pcdIndicator) ? studentAcademicPlan.pcdIndicator : null);

    const facultyRoles = applicationRoles.filter(applicationRole => applicationRole.startsWith(APPLICATION_ROLE_GRAD_FACULTY_PREFIX));
    const facultyPrograms = facultyRoles.map(facultyRole => (facultyRole.replace(APPLICATION_ROLE_GRAD_FACULTY_PREFIX, "")));
    const isFaculty = (facultyRoles.length > 0);

    const gradPlanAssociateRoles = applicationRoles.filter(applicationRole => applicationRole.startsWith(APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX));
    const gradPlanAssociatePlans = gradPlanAssociateRoles.map(gradPlanAssociateRole => (gradPlanAssociateRole.replace(APPLICATION_ROLE_GRAD_PLAN_ASSOCIATE_PREFIX, "")));
    const gradPlanAdminRoles = applicationRoles.filter(applicationRole => applicationRole.startsWith(APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX));
    const gradPlanAdminPlans = gradPlanAdminRoles.map(gradPlanAdminRole => (gradPlanAdminRole.replace(APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX, "")));
    const gradPlanAdminPrograms = uniq(gradPlanAdminRoles.map(gradPlanAdminRole => {
        const academicPlan = gradPlanAdminRole.replace(APPLICATION_ROLE_GRAD_PLAN_ADMIN_PREFIX, "");
        return academicPlan.split(DELIMITER.ACADEMIC_PLAN)[0];
    }));
    const isGradPlanAdmin = (gradPlanAdminRoles.length > 0);
    const isGradPlanAssociate = (!isGradPlanAdmin && (gradPlanAssociateRoles.length > 0)); // cannot be both a grad plan associate and admin, but ensuring that here jic

    const hasAuthorizedRole = ([isContactApprover, isEnrollmentAdmin, isFaculty, isFacultyApprover, isGradPlanAdmin, isGradPlanAssociate, isGraduationAdmin, isInquirer, isStudent].includes(true));

    return {
        academicPlan,
        applicationRoles,
        careerNumber,
        email: email,
        facultyPrograms,
        facultyRoles,
        fullName: `${given_name} ${family_name}`,
        gradPlanAssociatePlans,
        gradPlanAdminPlans,
        gradPlanAdminPrograms,
        hasAuthorizedRole,
        hasDoctoralAcademicPlan,
        hasProfessionalAcademicPlan,
        initials: `${given_name && given_name.charAt(0)}${family_name && family_name.charAt(0)}`.toUpperCase(),
        isContactApprover,
        isEnrollmentAdmin,
        isFaculty,
        isFacultyApprover,
        isGradPlanAdmin,
        isGradPlanAssociate,
        isGradStudent,
        isGraduationAdmin,
        isInquirer,
        isProfessionalStudent,
        isStudent,
        name: displayName ? displayName : name,
        osuid,
        pcdIndicator
    }
}

export { transformUser, transformExternalUser }