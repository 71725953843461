import React, { Fragment, useEffect, useState } from "react";
import { forOwn } from "lodash";
import { differenceInHours, differenceInMinutes, format, isAfter, isBefore, isValid, isWeekend, parseISO, subHours } from "date-fns";
import { inputMappings } from "../../../../util/enums";
import { Mui } from "@osu/react-ui";
import DatePicker from "./DatePicker";
import TimePicker from "./TimePicker";
import { alterTimeToMatchDate, createDateTimeQuestions, formatDate } from "./util";
import { DATE_FORMAT, TIME_FORMAT } from "../../../../util/constants";


function DateTimeGroup(props = {}) {
  const {
    id,
    value,
    type,
    onChange,
    className,
    placeholder,
    startTime,
    endTime,
    validation,
    helperText: summary,
    ...rest
  } = props;
  const currentValue = Object.prototype.hasOwnProperty.call(value || {}, 'value') ? value.value : value
  let dateId = `${id}-date`;
  let startId = startTime && `${id}-start`;
  let endId = endTime && `${id}-end`;
  let helperText = summary
  const [groupDateValues, setGroupDateValues] = useState({
    [dateId]: null,
    [startId]: null,
    [endId]: null,
  })
  const syncDateValues = JSON.stringify(currentValue || {}) === JSON.stringify(groupDateValues || {})
  useEffect(() => {
    const keysOfCurrentState = Object.keys(currentValue || {})
    const allowStateUpdate = (keysOfCurrentState || []).filter(key => {
      return [dateId, startId, endId].includes(key)
    }).length
    if(!syncDateValues && allowStateUpdate) {
      setGroupDateValues(currentValue)
    }
  }, [currentValue, dateId, endId, startId, syncDateValues])

  const questionsToRender = createDateTimeQuestions(dateId, startId, endId);
  const dateOfDay = (groupDateValues?.[dateId] ? new Date(groupDateValues?.[dateId]) : null);

  const { minDate, maxDate, ...remaining } = createValidationFields(
    groupDateValues,
    validation,
    dateId,
    startId,
    endId
  );

  return (
    <Fragment>
      <Mui.Box display="flex">
        {questionsToRender.map(({ type, id: currentQId, title }, idx) => {
          let cls = rest.className || "";
          const onGroupChange = (val) => {
            let value = val;
            if(isValid(value)) {
              if(currentQId !== dateId) value = alterTimeToMatchDate(dateOfDay, val)
              value.setSeconds(0);
              value.setMilliseconds(0);
              value = value.toISOString() 
            }
            setGroupDateValues((existingState = {}) => {
              const newValues = {
                ...existingState,
                [currentQId]: value
              }
              if(currentQId === dateId) {
                forOwn(newValues, (newValue, newKey) => {
                  if(newKey !== dateId) {
                    newValues[newKey] = (newValue ? alterTimeToMatchDate(val, parseISO(newValue)).toISOString() : newValue);
                  }
                });
              }
              onChange(newValues)
              return newValues
            });
          };
          if (helperText) {
            cls = undefined;
          }
          if (questionsToRender.length - 1 !== idx) {
            cls += " margin-right-2";
          }
          const preparedValue = formatDate(groupDateValues?.[currentQId])
          const errors = remaining?.[currentQId]?.errors || []
          if (type === inputMappings.time) {
            const formattedTime = (isValid(minDate) ? format(minDate,TIME_FORMAT) : null);
            const { dataField, ...timePickerProps} = rest;
            return (
              <TimePicker
                error={errors?.length}
                helperText={errors?.[0]}
                placeholder={formattedTime}
                {...timePickerProps}
                id={currentQId}
                key={currentQId + idx}
                label={title}
                className={cls}
                initialFocusedDate={formattedTime}
                minDate={minDate}
                maxDate={maxDate}
                onChange={onGroupChange}
                value={preparedValue}
              />
            );
          }
          return (
            <DatePicker
              {...rest}
              initialFocusedDate={new Date()}
              key={currentQId + idx}
              onChange={onGroupChange}
              id={currentQId}
              label={title}
              className={cls}
              value={preparedValue}
            />
          );
        })}
      </Mui.Box>
      {helperText && (
        <Mui.FormHelperText className={className}>
          {helperText}
        </Mui.FormHelperText>
      )}
    </Fragment>
  );
}

const mergeDateWithTime = (date, time) => {
  if (!time) {
    return null;
  }
  let formattedDate = new Date(date);
  formattedDate = isValid(formattedDate) ? formattedDate : null;
  formattedDate = formattedDate && format(formattedDate, DATE_FORMAT);
  if (!formattedDate) {
    return null;
  }
  formattedDate += ` ${time}`;
  return new Date(formattedDate)
};


const createValidationFields = (
  stateValues,
  validation,
  dateId,
  startId,
  endId
) => {
  let values = {
    errors: [],
    [startId]: {
      errors: []
    },
    [endId]: {
      errors: []
    }
  }
  const date = stateValues?.[dateId] && new Date(stateValues[dateId])
  const startTime = stateValues?.[startId] && new Date(stateValues[startId])
  const endTime = stateValues?.[endId] && new Date(stateValues[endId])
  const formattedStartTime = isValid(startTime) && format(startTime, TIME_FORMAT)
  
  values.minDate = mergeDateWithTime(date, validation?.begin);
  values.maxDate = mergeDateWithTime(date, validation?.end);
  if(isValid(startTime) && isBefore(startTime, values.minDate)) {
    values[startId].errors.push(`Please select a time after ${format(values.minDate, TIME_FORMAT)}`)
  }
  if(isValid(endTime) && isAfter(endTime, values.maxDate)) {
    values[endId].errors.push(`Please select a time before ${format(values.maxDate, TIME_FORMAT)}`)
  }
  if(isAfter(startTime, endTime)) {
    values[endId].errors.push(`Please select a time after ${formattedStartTime}`)
  }

  if(validation?.weekend) {
    const weekend = isWeekend(date)
    if(weekend) {
      values[dateId].errors.push(`Please select a university business day.`)
    }
  }

  if(validation?.durationInHours && isValid(endTime) && isValid(startTime)) {
    const differenceBetween = differenceInMinutes(endTime, startTime)
    const convertedHoursToMin = (validation.durationInHours * 60)
    const subtractEarliestStartTime = subHours(values.maxDate, validation.durationInHours)

    if (isAfter(startTime, subtractEarliestStartTime)) {
      values[startId].errors.push(`Event should start by ${format(subtractEarliestStartTime,TIME_FORMAT )}, so it can be at least 2 hours and end before ${format(values.maxDate, TIME_FORMAT)}`)
    }
    if(differenceBetween < convertedHoursToMin) {
      values[endId].errors.push(`Please select a time two hours after ${formattedStartTime}`)
    }
  }
  return values
};

DateTimeGroup.defaultProps = {
  value: null,
};

export default DateTimeGroup;
