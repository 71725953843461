import {formDataEnum} from "./formDataEnum";


export const formData = formDataEnum;
export const inputMappings = {
  inputGroup: "inputGroup",
  textInput: "textInput",
  dropDown: "dropDown",
  checkbox: "checkbox",
  radio: "radio",
  subheading: "subheading",
  lookup: "lookup",
  fileUpload: "fileUpload",
  date: "date",
  time: "time",
  datetime: "datetime",
  phone: "phone",
  street: "street",
};

export const loggedinUserData = {
  cumUndergradGpa: 'cumUndergradGpa',
  mailingAddress: 'mailingAddress'
}

export const dropdownSubtypes = {
  graduatePrograms: "graduatePrograms",
  facultyRank: "facultyRank",
  graduationLevel: "graduationLevel",
  term: "term", // SIS
  advisor: "advisor",
  coAdvisor: "coAdvisor",
  facultyMember: "facultyMember",
  finalDocumentLevels: "finalDocumentLevels",
  finalExamLevels: "finalExamLevels",
  designationMinor: "designationMinor",
  designationGIS: "designationGIS",
  careerType: "careerType",
  creditType: "creditType",
  certicatePlans: "certicatePlans",
  specialization: "specialization",
  academicUnit: "academicUnit",
  degrees: "degrees",
  candidacyType: "candidacyType",
  examType: "examType", 
  examReason: "examReason",
  gradFacultyRepresentative: "gradFacultyRepresentative",
  proposedAdvisor: "proposedAdvisor"
}

export const asyncOptions = [
  dropdownSubtypes.graduatePrograms, 
  dropdownSubtypes.term,
  dropdownSubtypes.degrees,
  dropdownSubtypes.certicatePlans,
  dropdownSubtypes.specialization,
  dropdownSubtypes.designationMinor,
  dropdownSubtypes.designationGIS,
  dropdownSubtypes.academicUnit,
  dropdownSubtypes.facultyRank
]

export const debouncedOptions = [
  dropdownSubtypes.advisor,
  dropdownSubtypes.coAdvisor,
  dropdownSubtypes.facultyMember,
  dropdownSubtypes.gradFacultyRepresentative,
  dropdownSubtypes.proposedAdvisor
]

export const graduateFacultyOptions = [
  dropdownSubtypes.advisor,
  dropdownSubtypes.coAdvisor,
  dropdownSubtypes.facultyMember,
  dropdownSubtypes.gradFacultyRepresentative,
  dropdownSubtypes.proposedAdvisor
];

export const graduationlevels = [
  {
    title: "Doctoral",
    value: "Doctoral",
    id: "doctoral"
  },
  {
    title: "DMA",
    value: "DMA",
    id: "dma"
  },
  {
    title: "Educ Specialist",
    value: "Educational Specialist",
    id: "educ-specialist"
  },
  {
    title: "Master's - Candidacy Exam",
    value: "Master_CE",
    id: "ma-candidacy-exam"
  },
  {
    title: "Master's - Non-thesis",
    value: "Master_NT",
    id: "ma-non-thesis"
  },
  {
    title: "Master's - Thesis",
    value: "Master_T",
    id: "ma-thesis"
  },
  {
    title: "Master's - Course Based",
    value: "MasterCB",
    id: "ma-course-based"
  },
  {
    title: "Professional",
    value: "Professional",
    id: "professional"
  }
]

export const finalDocumentLevels = graduationlevels.filter(({ value }) => {
  return [
    "DMA",
    "Doctoral",
    "Master_T"
  ].includes(value)
})

export const finalExamLevels = graduationlevels.filter(({ value }) => {
  return [
    "DMA",
    "Doctoral",
  ].includes(value)
})

export const synchronousOptions = {
  [dropdownSubtypes.graduationLevel]: graduationlevels,
  [dropdownSubtypes.finalDocumentLevels]: finalDocumentLevels,
  [dropdownSubtypes.finalExamLevels]: finalExamLevels,
  [dropdownSubtypes.careerType]: [
    {
      id: 'undergraduate',
      title: 'Undergraduate',
      value: 'UNDERGRAD',
    },
    {
      id: 'Graduate',
      title: 'Graduate',
      value: 'GRAD'
    },
    {
      id: 'Professional',
      title: 'Professional',
      value: 'PROF'
    }
  ],
  [dropdownSubtypes.creditType]: [
    {
      id: 'outside-institution',
      title: 'Outside Institution',
      value: 'OUTSIDE'
    },
    {
      id: 'graduate-non-degree',
      title: 'Graduate Non-degree',
      value: 'GRADNONDEGREE'
    },
    {
      id: 'post-degree-credit',
      title: 'Post-degree credit',
      value: 'POSTDEGREE'
    },
    {
      id: 'senior-petition',
      title: 'Senior Petition',
      value: 'SENIORPETITION'
    }
  ],
  [dropdownSubtypes.candidacyType]: [ 
    { 
      id: 'first', 
      title: 'First Candidacy', 
      value: 'First Candidacy', 
    },  
    { 
      id: 'second', 
      title: 'Second Candidacy', 
      value: 'Second Candidacy' 
    }, 
    { 
      id: 'supplemental', 
      title: 'Supplemental Candidacy', 
      value: 'Supplemental Candidacy' 
    } 
  ], 
  [dropdownSubtypes.examType]: [ 
    { 
      id: 'first', 
      title: 'First Final Exam', 
      value: 'First Final Exam', 
    }, 
    { 
      id: 'second', 
      title: 'Second Final Exam', 
      value: 'Second Final Exam' 
    } 
  ], 
  [dropdownSubtypes.examReason]: [ 
    { 
      id: 'reason1', 
      title: 'Semester Extension', 
      value: 'Semester Extension', 
    }, 
    { 
      id: 'reason2', 
      title: 'Rule Waiver', 
      value: 'Rule Waiver' 
    }, 
    { 
      id: 'reason3', 
      title: 'Non OSU Graduate Faculty', 
      value: 'Non OSU Graduate Faculty' 
    }, 
    { 
      id: 'reason4', 
      title: 'Former OSU Graduate Faculty', 
      value: 'Former OSU Graduate Faculty' 
    }, 
    { 
      id: 'reason5', 
      title: 'Video Conference', 
      value: 'Video Conference' 
    }, 
    { 
      id: 'reason6', 
      title: 'Other', 
      value: 'Other' 
    } 
  ], 
  [dropdownSubtypes.eosOption]: [ 
    { 
      id: 'option1', 
      title: 'Yes', 
      value: true, 
    }, 
    { 
      id: 'option2', 
      title: 'No', 
      value: false 
    } 
  ] 
}