import { Mui } from "@osu/react-ui";
import React from "react";
import { getSyncOptions, isAsyncDropdownSubtype } from "../../../util/functions";
import AsyncDropdown from "./AsyncDropdown";

function Dropdown(props = {}) {
  const { choices, id, value, label, onChange, callback, subtype, sectionState, error, helperText, dataField, ...rest } = props;

  const asynchronous = !!callback || isAsyncDropdownSubtype(subtype)
  
  if(asynchronous) {
    return <AsyncDropdown 
    id={id}
    value={value}
    label={label}
    subtype={subtype}
    callback={callback}
    onChange={onChange}
    sectionState={sectionState}
    error={error}
    {...rest}
    />
  }
  const syncOptions = getSyncOptions(subtype)
  const options = syncOptions?.length ? syncOptions : choices

  return (
    <Mui.FormControl variant="outlined" error={!!error} {...rest}>
      <Mui.InputLabel id={id + "-label"}>{label}</Mui.InputLabel>
      <Mui.Select
        labelId={id + "-label"}
        id={id}
        value={value?.value || value}
        onChange={(event) => {
          const val = event?.target?.value
          const matchingOption = options.find(opt => opt.value === val)
          onChange(matchingOption || event) 
        }}
        label={label}
      >
        {options.map(({ value, title, id }, index) => {
          return (
            <Mui.MenuItem key={`choice-${id}-${index}`} value={value}>
              {title}
            </Mui.MenuItem>
          );
        })}
      </Mui.Select>
      <Mui.FormHelperText>{error}</Mui.FormHelperText>
    </Mui.FormControl>
  );
}

Dropdown.defaultProps = {
  choices: [],
  value: ''
}

export default Dropdown