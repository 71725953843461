import { connect } from "react-redux";
import Mapper from "../components/Mapper";
import formDefinitions from "../paths";
import { DROPDOWNS, SUBMIT_TEMPLATE_FORM, onFormSave, onFormSubmit, getSections, resetState } from "../../actions-index";
import { dropdownSubtypes } from "../../util/enums.js";
import { formatInitialState } from "../util";

const mapStateToProps = (state, ownProps) => {
  const formType = ownProps?.match?.params?.formType;
  const formId = ownProps?.match?.params?.formId;
  const { sections, formType: ft } = formDefinitions?.[formType] || {};
  const editScreen = ownProps?.match?.pathname?.includes('/edit/')
  const submissionId = state?.form?.submission?.id
  const submissionOSUID = state?.form?.submission?.osuid
  const submissionError = state?.form?.submission?.status === "error"
  const submissionErrorMessage = state?.form?.submission?.message;
  const isValidating = (ownProps?.isValidating ?? false);

  const existingEditableForm = state?.form?.edit?.[formId]?.form ?? {}
 let initialState = {}
  const { initialState: is, formSections } = formatInitialState(
    sections
  );
  if(editScreen && Object.keys(existingEditableForm).length) {
    initialState = existingEditableForm
  } else {
    initialState = is
  }
  const errorState = state?.templateForm?.errors?.[formType]?.state;
  const formStatus = (state?.form?.submission?.status ?? state?.form?.edit?.[formId]?.status)
  const loadGetFormCall = editScreen && !Object.keys(existingEditableForm).length && !formStatus
  const loading = (isValidating === true || (!existingEditableForm && loadGetFormCall) || ['initialized', 'loading'].includes(state?.form?.edit?.[formId]?.status));
  const user = (state?.authentication?.user ?? {});


  return {
    formId,
    formType: ft,
    initialState,
    errorState,
    formSections,
    loadGetFormCall,
    loading,
    submissionId,
    submissionOSUID,
    submissionError,
    submissionErrorMessage,
    user,
    status: formStatus
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSave: (values) => dispatch(onFormSave(values)),
    onSubmit: (values) => dispatch(onFormSubmit(values, ownProps?.validateSubmit)),
    getSections: (filter = {}) => dispatch(getSections(filter)),
    resetSubmission: () => dispatch(resetState(SUBMIT_TEMPLATE_FORM), {}),
    resetDropdowns: () => {
      dispatch(resetState(DROPDOWNS, { type: dropdownSubtypes.advisor }));
      dispatch(resetState(DROPDOWNS, { type: dropdownSubtypes.coAdvisor }));
      dispatch(resetState(DROPDOWNS, { type: dropdownSubtypes.facultyMember }));
      dispatch(resetState(DROPDOWNS, { type: dropdownSubtypes.gradFacultyRepresentative }));
      dispatch(resetState(DROPDOWNS, { type: dropdownSubtypes.proposedAdvisor }));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Mapper);
