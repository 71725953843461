// *https://www.registers.service.gov.uk/registers/country/use-the-api*
import React from "react";
import { Mui } from "@osu/react-ui";
import withDropdownOptions from "../../hocs/withDropdownOptions";
import withOptionsFilter from "../../hocs/withOptionsFilter";

const Autocomplete = withDropdownOptions(withOptionsFilter(Mui.Autocomplete))

function AsyncDropdown(props = {}) {
  const { options, className, onChange, error, filterState, helperText, sectionState, dataField, ...rest } = props;
  const [open, setOpen] = React.useState(false)
  return (
    <Mui.FormControl variant="outlined" {...rest} className={className} error={!!error}>
      <Autocomplete
        sx={{ minWidth: 'inherit', minHeight: 'inherit' }}
        style={{ minWidth: 100 }}
        onChange={(event, newValue) => {
          onChange(newValue)
        }}
        open={open}
        name={rest?.id}
        onOpen={() => {
          setOpen(true);
        }}
        onClose={() => {
          setOpen(false);
        }}
        isOptionEqualToValue={(option, value) => {
          return (option.value === value.value);
        }}
        getOptionLabel={(option) => {
          return (option?.selectedTitle || option?.title || "");
        }}
        options={options}
        groupBy={(option) => (option.group || null)}
        PaperComponent={(props) =>  <Mui.Paper {...props} elevation={8} />}
        error={!!error}
        sectionState={sectionState}
        filterState={filterState}
        onKeyDown={(e) => {
          if(e.key === "Enter") {
            e.preventDefault(); // prevent form submission
          }
          if(open) {
            setOpen(false);
          } else {
            setOpen(true)
          }
        }}
        {...rest}
      />
      <Mui.FormHelperText>{error}</Mui.FormHelperText>
    </Mui.FormControl>
  );
}

export default AsyncDropdown