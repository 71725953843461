import { SET_USER, USER_STATUS } from '../actions-index';
import { ACTION_STATUS } from '../util/constants';

export function authentication(state = {}, action = {}) {
    const { payload = {} } = action;
    let { status } = state;
    if(payload && (payload.osuid || payload.externalUserId))  {
        if(payload.hasAuthorizedRole) {
            status = ACTION_STATUS.SUCCESS;
        } else {
            status = ACTION_STATUS.UNAUTHORIZED;
        }
    }
    switch (action.type) {
        case SET_USER:
            return { 
                user: payload, 
                status
            };
        case USER_STATUS:
            return {
                ...state,
                status: action.payload
            };
        default:
            return state
    }
}

