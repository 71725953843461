import { Mui, MuiStyles } from "@osu/react-ui";
import React, { Fragment } from "react";
import InputMapper from "./Mapper";

const InputGroupRow = (props = {}) => {
  const { updateRow, deleteRow, row, questions, disableDeletion, disabled, defaultValue, className,hideDeletion, error } = props;
  const theme = MuiStyles.useTheme();
  const alterView = questions?.length > 3
  const sx = (!alterView ? { flexBasis: `${100/(questions?.length || 1)}%`, marginRight: theme.spacing(2) } : undefined);

  if (!questions?.length) {
    return null;
  }

  const onChange = ({ id, value, dataField, required }) => {
    updateRow({
      [id]: value,
      value,
      required,
      dataFieldMapping: {
        [id]: dataField
      }
    });
  };

  let questionsArray = questions.map((question = {}, qIdx) => {
    const { gridProps = {}, dataField } = question
    const val = defaultValue?.[question?.id] || row?.[question?.id]
    let inputMapper = <InputMapper 
      error={question.required === true && !val && error}
      key={`input-mapper=${question?.id}-${qIdx}`}
      value={val}
      onChange={({ id, value }) => {
        onChange({ id, value, dataField })
      }}
      sx={sx}
      disabled={disabled}
      fullWidth={alterView}
      {...question}
    />
    if(alterView) {
      return <Mui.Grid key={`$input-mapper=${question?.id}-${qIdx}-grid`} item xs={12} md={4} {...gridProps}>
        {inputMapper}
      </Mui.Grid>
    }
    return inputMapper
  });

  return (
    <Fragment>
      <fieldset className={`padding-0 display-flex align-items-center margin-bottom-2 ${className ? " " + className : ""}`}>
        {alterView ? (
          <Mui.Grid container spacing={1}>
            {questionsArray}
          </Mui.Grid>
        ) : (
          questionsArray
        )}
        
        {!hideDeletion && <Mui.Tooltip title={(disableDeletion || disabled) ? "Disabled" : "Remove"}>
          <span>
            <Mui.IconButton
              aria-label="delete"
              disabled={disableDeletion || disabled}
              onClick={() => deleteRow()}
            >
              {(disableDeletion || disabled) 
              ? <Mui.SvgIcon>
                <path d="M17 11v2h-1.46l4.68 4.68C21.34 16.07 22 14.11 22 12c0-5.52-4.48-10-10-10-2.11 0-4.07.66-5.68 1.78L13.54 11H17zM2.27 2.27 1 3.54l2.78 2.78C2.66 7.93 2 9.89 2 12c0 5.52 4.48 10 10 10 2.11 0 4.07-.66 5.68-1.78L20.46 23l1.27-1.27L11 11 2.27 2.27zM7 13v-2h1.46l2 2H7z"></path>
              </Mui.SvgIcon> 
              : <Mui.SvgIcon>
                <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm5 11H7v-2h10v2z"></path>
              </Mui.SvgIcon>}
            </Mui.IconButton>
          </span>
        </Mui.Tooltip>}
      </fieldset>
    </Fragment>

  );
};

export default InputGroupRow;
