import { Mui } from "@osu/react-ui";
import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { links } from "../paths";
import StandardTemplate from "../templates/Standard";

function Mapper(params) {
  const {
    formSections,
    initialState,
    onSubmit,
    onSave,
    errorState,
    getSections,
    formType,
    formId,
    loadGetFormCall,
    loading,
    submissionId,
    submissionOSUID,
    submissionError,
    submissionErrorMessage,
    navigate,
    location,
    match,
    resetDropdowns,
    resetSubmission,
    user,
    status
  } = params;
  const [alert, setAlert] = useState(null)
  const [isSubmitting, setIsSubmitting] = useState(null)

  useEffect(() => {
    if(loadGetFormCall && formId) {
      getSections({ id: formId, osuid: match?.params?.osuid, formType });
    }
  }, [getSections, formId, formType, loadGetFormCall, match?.params?.osuid]);

  useEffect(() => {
    if(errorState && !loading && alert?.severity !== "error") {
      setAlert({
        loading: false,
        title: "Validation Errors",
        message: "The form has validation errors that must be resolved before the form can be submitted.",
        severity: "error"
      });
    }
  }, [alert, errorState, loading, setAlert]);

  useEffect(() => {
    if(submissionError && isSubmitting) {
      setAlert({
        loading: false,
        title: "Error",
        message: (submissionErrorMessage ?? "Error saving form"),
        severity: "error"
      })
    }
  }, [submissionError, submissionErrorMessage, isSubmitting])

  useEffect(() => {
    let pageToGoTo = 'edit'
    if(isSubmitting && status === 'success') {
      pageToGoTo = 'view'
    }
    if(submissionId && pageToGoTo) {
      let newLocation = (location?.pathname || "").endsWith('create') ? location.pathname.replace('create', pageToGoTo) : ""
      if(newLocation) {
        newLocation += `/${submissionId}`
        if(submissionOSUID) newLocation += `/${submissionOSUID}`

        console.log(`newLocation has ${newLocation}, pageToGoTo has ${pageToGoTo}, location.pathname has ${location.pathname}`);

        navigate(newLocation, { replace: true }); 
      }
    }
    setAlert({
      loading: false
    })
  }, [navigate, isSubmitting, location.pathname, status, submissionId, submissionOSUID])

  useEffect(() => {
    return () => {
      resetDropdowns();
      resetSubmission();
    };
  }, [resetDropdowns, resetSubmission]);

  useEffect(() => {
    if(!loading) {
      console.log("****** Not loading so set Alert to empty object")
      setAlert({})
    }
  }, [loading])

  if (!formType) {
    return (
      <Mui.List>
        {links.map((link) => (
          <Mui.ListItem>
            <Mui.Link component={Link} to={link.path}>
              {link.title}
            </Mui.Link>
          </Mui.ListItem>
        ))}
      </Mui.List>
    );
  }

  const handleSave = (formData) => {
    onSave({
      id: formId,
      formType,
      formData,
      user
    });
    setAlert({
      message: "Saving your form...",
      // loading: true
    })
  };

  const handleSubmit = (formData, validity) => {
    setIsSubmitting(true)
    onSubmit({
      id: formId,
      formType,
      formData,
      user
    });
  };

  console.log(`loading is ${loading} and alert?.loading is ${alert?.loading}`)
  return (
    <Fragment>
      {alert?.message && <Mui.Alert className="margin-bottom-2" severity={alert?.severity ?? "info"}>
          {alert?.title && <Mui.AlertTitle>
            {alert.title}
          </Mui.AlertTitle>}
          {alert.message}
        </Mui.Alert>}
      <StandardTemplate
        formSections={formSections}
        onSubmit={handleSubmit}
        onSave={handleSave}
        initialState={initialState}
        errorState={errorState}
        loading={loading || alert?.loading}
      />
    </Fragment>
  );
}

export default Mapper;
