import { PERSON, JOB_CODES, DROPDOWNS, STUDENT_INFORMATION } from "../actions-index.js";
import { deepCopy } from "../util/functions.js";

export function person(state = {}, action = {}) {
    let newState = (action.type === PERSON ? action.payload : state);
    let archive = {}
    if(state?.archive) {
        archive = Object.assign({}, state.archive)
    }
    if(action?.payload?.data?.emplid) {
        archive[action.payload.data.emplid] = action.payload.data
    }
    newState.archive = archive
    return newState
}

export function jobCodes(state = {}, action = {}) {
    return (action.type === JOB_CODES ? action.payload : state);
}

export function dropdowns(state = {}, action = {}) {
    let newState = deepCopy({}, state)
    if(action.type === DROPDOWNS) {
        const { type, data = [], status, query } = action?.payload || {}
        
        if(type) {
            if(!newState[type]) {
                newState[type] = {
                    status,
                    queries: (newState?.[type]?.queries ?? []),
                    options: (newState?.[type]?.options ?? []),
                    _options: (newState?.[type]?._options ?? []),
                }
            }
            if(query && data.length > 0) {
                if(!Array.isArray(newState?.[type]?.queries)) {
                    newState[type].queries = []
                }
                let formattedOptions = data.concat(newState?.[type]?._options || [])
                formattedOptions = new Set(formattedOptions)
                newState[type].options = [...formattedOptions]
                newState[type]._options = [...formattedOptions]
            } else if (Array.isArray(data)) {
                newState[type].options = data
                newState[type]._options = data
            }
            if(status) {
                newState[type].status = status
            } else if(newState?.[type]?.options?.length) {
                newState[type].status = 'success'
            } else if(!newState?.[type]?.status ) {
                newState[type].status = 'error'
            }
            newState[type].queries.push(query)
        }
    }

    return newState
}

export function studentInformation(state = {}, action = {}) {
    let newState = { ...state }
    if(action.type === STUDENT_INFORMATION) {
        const osuid = action?.payload?.osuid    
        const status = action?.payload?.status    
        if(status) {
            newState.status = status
        }
        if(osuid) {
            newState[osuid] = {
                ...action.payload
            }
            newState.status = 'success'
        }
    }
    return newState
}