import { generateClient } from "aws-amplify/api";
import { transformExternalUser, transformUser } from "./transform";
import { SET_USER, USER_STATUS } from "../actions-index";
import * as Queries from "../graphql/queries";
import * as CustomQueries from "../graphql/custom";
import { ACTION_STATUS, AWS_LAMBDA } from "../util/constants";

const API = generateClient();

async function getStudentAcademicPlan(emplid) {
    const response = await API.graphql({ query: Queries.getStudentAcademicPlan, variables: { emplid } });
    return (response?.data?.getStudentAcademicPlan?.studentAcademicPlan ?? null);
}

async function buildUser(obj = {}) {
    const studentAcademicPlan = await getStudentAcademicPlan(obj.osuid);
    return transformUser(obj, studentAcademicPlan);
}

export function recievedSetUser(user) {
    return {
        type: SET_USER,
        payload: Object.keys(user || {}).length > 0 ? user : null
    }
}

export function setUserStatus(status) {
    return {
        type: USER_STATUS,
        payload: status
    }
}

export function getExternalUserInfo(externalUserId, accessToken) {
    return async (dispatch, getState) => {
        let err;
        dispatch(setUserStatus("loading"))
        try {
            const graphqlParams = {
                query: CustomQueries.getExternalUserInfo,
                authMode: AWS_LAMBDA,
                authToken: `${externalUserId}:${accessToken}`
            }
            const response = await API.graphql(graphqlParams);
            const data = response?.data?.getExternalUserInfo;
                if(response?.status) {
                    dispatch(setUserStatus(response?.status))
                }
                if(['error', 'cleared'].includes(response?.status)) {
                    dispatch(recievedSetUser({}));
                } else {
                    const user = transformExternalUser({...data, externalKeys: { akey: accessToken, ukey: externalUserId}})
                    dispatch(recievedSetUser(user))
                }
            
        } catch (error) {
            err = error;
            console.error("getExternalUser Action-ERROR:", error)
            throw error;
        }
        if(!err){
            dispatch(setUserStatus("success"))
        }
    }
}

export function receiveAmplifyHubUser(response = {}) {
    return async (dispatch, getState) => {
        try {
            const state = getState();
            const authStatus = state.authentication.status;
            const stateOsuId = state?.authentication?.user?.osuid
            const responseOsuid = response?.osuid || response?.displayName

            if(authStatus !== ACTION_STATUS.UNAUTHORIZED) {
                if(response?.status) {
                    dispatch(setUserStatus(response?.status))
                }
                if(['error', 'cleared'].includes(response?.status)) {
                    dispatch(recievedSetUser({}));
                } else if(responseOsuid && (stateOsuId !== responseOsuid)) {
                    const user = await buildUser(response);
                    dispatch(recievedSetUser(user))
                    dispatch(setUserStatus("success"))
                }
            }   
        } catch (error) {
            console.error(error)
            dispatch(setUserStatus("error"))
        }
    }
}