import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { invert } from "lodash";
import { checkPendingForm } from "../actions";
import { FORM_TYPE_HTML_FRIENDLY } from "../../util/constants";

export default function ApplicationForCandidacy(props = {}) {
  const { Component, match = {} } = props;
  const { params = {}, pathname = "" } = match;
  const { formType: formTypeFriendly } = params;
  const formType = invert(FORM_TYPE_HTML_FRIENDLY)[formTypeFriendly];
  const isCreate = pathname.includes("/create");
  
  const navigate = useNavigate();
  const user = useSelector((state) => (state?.authentication?.user ?? {}));
  const { academicPlan = null, hasDoctoralAcademicPlan = false, osuid } = user;
  const [hasValidated, setValidated] = useState(false);
  const [isValidating, setValidating] = useState(false);
  const validateSubmit = useCallback(async () => {
    const hasPendingForm = await checkPendingForm({ formType, studentid: osuid }, false);
    if(hasPendingForm) return "Unable to submit Application for Candidacy. There is an existing Pending or Approved Application with the Graduate School."
    return null;
  }, [formType, osuid]);

  const navigateToLandingPage = useCallback((errorMessage) => {
    navigate(`/form/${formTypeFriendly}`, { state: { errorMessage }});
  }, [formTypeFriendly, navigate]);

  const validate = useCallback(async () => {
    setValidating(true);
    if(isCreate) {
      if(!academicPlan) return navigateToLandingPage("Unable to find a valid academic plan.  If you are a current student, please contact your graduate office.");
      if(!hasDoctoralAcademicPlan) return navigateToLandingPage("We were unable to find an enrollment for a doctoral academic plan.  Please contact your graduate program office.");
      const hasPendingForm = await checkPendingForm({ formType, studentid: osuid }, true);
      if(hasPendingForm) return navigateToLandingPage(`There is an existing New, Pending or Approved Application with the Graduate School for ${academicPlan}.`);
    }
    setValidating(false);
    setValidated(true);
  }, [academicPlan, formType, hasDoctoralAcademicPlan, isCreate, navigateToLandingPage, osuid]);

  // when not validated, then validate
  useEffect(() => {
    if(hasValidated === false && isValidating === false) validate();
  }, [hasValidated, isValidating, validate]);

  return (<Component {...props} isValidating={isValidating} validateSubmit={validateSubmit} />);
}