import History from "./containers/History";
import Responses from "./containers/Responses";
import { getFormByType } from "../Forms/paths";
import { constructFacultyFields } from "../Common/transform";
import { graduateFacultyOptions } from "../util/enums";
import StudentInfo from "./containers/StudentInfo";

const steps = [
  {
    Component: StudentInfo,
    id: "student-info",
    label: "Student Information",
    redux: ""
  },
  {
    Component: Responses,
    id: "form-response",
    label: "Form Responses",
  },
  {
    Component: History,
    id: "history",
    label: "History",
  },
];

function buildFormDetailFields(form, dataFields = []) {
  const formDetailFields = [];
  for(const dataField of dataFields) {
    const tokens = dataField.split(".");
    const field = tokens[0];
    const nestedField = (tokens.length > 1 ? tokens[1] : null);
    let formField;
    if(nestedField) {
      if(Array.isArray(form[field])) {
        formField = form[field][0][nestedField];
      } else {
        formField = form[field][nestedField];
      }
    } else {
      formField = form[field];
    }
    if(formField?.label) formDetailFields.push(formField);
  }
  return formDetailFields;
}

function transformLandingForms(landingForms = []) {
  const forms = [];
  for(const landingForm of landingForms) {
    const formDefinition = getFormByType(landingForm.formType);
    if(formDefinition) {
      forms.push({
        formId: landingForm.id,
        name: landingForm.name,
        osuid: landingForm.studentid,
        form: formDefinition.title,
        formDetailFields: buildFormDetailFields(landingForm, formDefinition?.formDetailFields),
        createdDate: landingForm.createdDate,
        status: landingForm.status,
        requesterId: landingForm.requesterId
      });
    }
  }
  return forms;
}

const constructMember = (member) => {
  let newMember = {
    ...member || {},
    ...constructFacultyFields(member) || {}
  }

  return newMember
}

function transformValueWithGraphqlMapping(form, question) {
  const { title, dataField, subtype } = question
  let value = []
  let responses = []
  question.graphql.forEach((graphqlMapping) => {
    if(graphqlMapping?.hidden !== true) {
      value.push({
        label: graphqlMapping.label,
        value: form?.[dataField]?.[graphqlMapping.dataField] ?? "-",
      })
    }
  })

  
  responses.push({
    label: title,
    value,
  });

  let editValue = form?.[dataField]
  if( graduateFacultyOptions.includes(subtype))  {
    editValue = constructMember(editValue?.value || editValue)
  }
  
  return {
    read: responses,
    edit: editValue
  }
}

export { buildFormDetailFields, steps, transformLandingForms, transformValueWithGraphqlMapping, constructMember };
